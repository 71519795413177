import parse from 'html-react-parser'
import React from 'react'

const html = `<style>
      @font-face{font - family:'Interstate';src:url('/fonts/-Regular.ttf') format('truetype');font-weight:300;font-style:normal;font-display:swap;}
    @font-face{font - family:'Interstate';src:url('/fonts/Interstate-Light.ttf') format('truetype');font-weight:300;font-style:normal;font-display:swap;}
    @font-face{font - family:'Interstate';src:url('/fonts/Interstate-Bold.ttf') format('truetype');font-weight:700;font-style:normal;font-display:swap;}
    body{font - family:'Interstate',sans-serif;}
    data-styled.g404[id="sc-global-fQcBp1"]{content:"sc-global-fQcBp1,"}
    body{color:#000;background:#fff;margin:0}.next-error-h1{border - right:1px solid rgba(0,0,0,.3)}
    a:link {color: black;}
    a:visited {color: black;}
    a:hover {color: black;}
    a:active {color: black;}
    @media
    (prefers-color-scheme:dark){
        body{color:#fff;background:#000}.next-error-h1{border-right:1px solid rgba(255,255,255,.3)}
        a:link {color: white;}
        a:visited {color: white;}
        a:hover {color: white;}
        a:active {color: white;}
    }
</style>
<div id="__next">
    <div
        style="font-family:system-ui,&quot;Segoe UI&quot;,Roboto,Helvetica,Arial,sans-serif,&quot;Apple Color Emoji&quot;,&quot;Segoe UI Emoji&quot;;height:100vh;text-align:center;display:flex;flex-direction:column;align-items:center;justify-content:center">
        <div style="line-height:48px">

            <h1 class="next-error-h1"
                style="display:inline-block;margin:0 20px 0 0;padding-right:23px;font-size:24px;font-weight:500;vertical-align:top">404</h1>
            <div style="display:inline-block">
            <h2 style="font-size:14px;font-weight:400;line-height:28px">Die Seite konnte nicht gefunden werden</h2></div>
            <br>
            <a href="###backlink###">Zur Startseite</a>
        </div>
    </div>
</div>`

export default function Custom404() {
  const backlink = `/${process.env.forcedTheme ?? 'sunpoint'}`
  const finalHtml = html.replace('###backlink###', backlink)
  return <>{parse(finalHtml)}</>
}
